body {
    margin: 0;
    font-family: 'Montserrat', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

body::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 10px;
}

body::-webkit-scrollbar-track {
    background: rgba(2, 64, 173, .3);
    border-radius: 0;
}

body::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: rgba(2, 64, 173, 0.6);
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}
